#viewDiv {
  padding: 0;
  margin: 0;
  height: 783px;
  width: 100%;
}

#instruction {
  z-index: 99;
  position: absolute;
  top: 15px;
  left: 50%;
  padding: 5px;
  margin-left: -175px;
  height: 30px;
  width: 355px;
  background: rgba(25, 25, 25, 0.8);
  color: white;
}

#distanceDiv {
  padding: 0;
  margin: 0;
  float: left;
  font-weight: 900;
}

#distanceDiv p {
  padding: 0;
  margin: 0.2em;
}
