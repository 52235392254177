.form-heading { color:#fff; font-size:23px;}
.panel h2{ color:#444444; font-size:18px; margin:0 0 8px 0;}
.panel p { color:#777777; font-size:14px; margin-bottom:30px; line-height:24px;}
.login-form .form-control {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}
.main-div {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 2px;
  margin: 10px auto 30px;
  max-width: max-content;
  /* padding: 50px 70px 70px 71px; */
}

.login-form .form-group {
  margin-bottom:10px;
}
.login-form{ text-align:center;}

.login-form  .btn.btn-success {
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0;
}
li{
  font-size: 13px;
  color: #777777;
}
.back { text-align: left; margin-top:10px;}
.back a {color: #444444; font-size: 13px;text-decoration: none;}
.lblloginerror, .lblapierror{
  color: red;
  font-weight: bold;
}
.lblapiSuccess{
  color: green;
  font-weight: bold;
}
.loader {
  height: 100vh;
  width: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.loader .spinner-border {
  position: absolute;
  top: 40%;
  left: 50%;
}
