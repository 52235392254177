.fontSize {
    font-size: x-large;
    font-weight: bold;

}
.detailsbgchange{
    background-color: #f2f2f2;
}
.btnstyle {
    background-color: white;
    border-color: black;
    color: black;
    font-size: 17px;
    border-radius: 8px;
    margin-right: 8px;
    margin-top: 8px;
    width: 105px;
    height: 35px;
    display: inline-block;
    text-decoration: none;
    position: relative;
}

.fontSizesm {
    font-size: 19px;
    font-weight: bold;
    margin-right: 10px;
}

.fontSizesm1 {
    color: #425b74;
}

.table {
    border-collapse: inherit;
    font-size: 0.700rem;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.tdbold {
    font-weight: bold;
}

.fontSizexs {
    padding-top: 7px;
    text-align: right;
    font-size: 11px;
    line-height: 21px;
    letter-spacing: 0.2px;
}

.row {
    text-align: justify;
}

.row>div {
    display: inline-block;
}

.fix {
    width: 100%;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 48%;
    display: inline-block;
    margin: 10px;
    height: 393px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.divInline {
    /* font-size: 0.700rem; */
    margin-top: 10px;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.1px;
    color: rgba(17, 17, 17, 0.64);
}

.imgwidthadjust {
    width: 775px;
}

.divans {
    font-size: 0.85rem;
    font-weight: bold;
}

.divInlineans {
    font-size: 0.85rem;
    font-weight: bold;
}
.textalignright{
    text-align: right;
    margin-top: -4px;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: 1.1px;
    color: rgba(17, 17, 17, 0.64);
}

.card2 {
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    margin-top:-392px;
    margin-right:-10px;
}

.btnOrderdetails {
    cursor: pointer;
    font-size: 20px;
    color: rgb(81, 79, 79);
}

.fontSizesm {
    font-size: 1.1rem;
}

.linkpointer {
    cursor: pointer;
}

.tableHeadFixed {
    overflow: auto;
    height: 300px;
    font-size: small;
}

.tableHeadFixed thead th {
    position: sticky;
    top: 0%;
    z-index: 1;
    background-color: white;
}

.OrdertableHeadFixed {
    overflow: auto;
    height: 583px;
}

.OrdertableHeadFixed thead th {
    position: sticky;
    top: 0%;
    z-index: 1;
    background-color: white;
}

.textcentre {
    text-align: center !important;
}

          